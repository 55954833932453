import ResetPassword from '../components/Login/ResetPassword'
import React from 'react'
import Pagewrapper from '../components/PageWrapper'

export default function () {
    return (
        <Pagewrapper>
            <ResetPassword />
        </Pagewrapper>
    )
}

import React from 'react'
import { useAuth } from '../../hooks/useAuth'

interface Props {
    title: string
    children: any
}

const PageLayout = ({ children, title }: Props) => {
    useAuth('/app/')

    return (
        <div>
            <main>{children}</main>
        </div>
    )
}

export default PageLayout
